.styles-module__jf27DW__wrapper {
  content-visibility: auto;
  min-height: 1140px;
}

.styles-module__jf27DW__newsfeedContent {
  height: 1200px;
  overflow: hidden;
}

@media (max-width: 300px) {
  .styles-module__jf27DW__newsfeedContent {
    height: 1000px;
  }
}

.styles-module__jf27DW__expanded {
  height: auto;
}

.styles-module__jf27DW__scrollAnchor {
  visibility: hidden;
  height: 0;
}

.styles-module__jf27DW__toggleButton {
  margin-top: 20px;
}

.styles-module__jf27DW__buttonIconWrapper {
  margin-top: 1px;
  margin-left: .5em;
}

.styles-module__jf27DW__buttonIconWrapper > span[class^="icon-"] {
  font-size: 8px;
}

.styles-module__jf27DW__arrowUp {
  margin-top: 5px;
  transform: rotate(180deg);
}

.styles-module__jf27DW__newsfeedItemWrapper, .styles-module__jf27DW__newsfeedItemWrapperSmall {
  min-height: 175px;
  max-height: 280px;
  text-decoration: none;
  display: flex;
}

.styles-module__jf27DW__newsfeedItemWrapper:not(:last-child) article, .styles-module__jf27DW__newsfeedItemWrapperSmall:not(:last-child) article {
  border-bottom: 1px solid var(--wcom-c-gray);
}

@media (min-width: 500px) {
  .styles-module__jf27DW__newsfeedItemWrapper img, .styles-module__jf27DW__newsfeedItemWrapperSmall img {
    height: auto;
  }
}

.styles-module__jf27DW__newsfeedItemWrapper span[class="icon-info-bubble"], .styles-module__jf27DW__newsfeedItemWrapperSmall span[class="icon-info-bubble"] {
  font-size: 36px;
}

.styles-module__jf27DW__newsfeedItemWrapper span[class="icon-warning-colored"], .styles-module__jf27DW__newsfeedItemWrapperSmall span[class="icon-warning-colored"] {
  justify-content: center;
  margin-top: 15px;
  font-size: 35px;
  display: flex;
}

.styles-module__jf27DW__newsfeedItemWrapper span[class="icon-arrow-link"], .styles-module__jf27DW__newsfeedItemWrapperSmall span[class="icon-arrow-link"] {
  color: var(--wcom-c-blueDark);
  font-size: 16px;
  position: absolute;
  top: 5px;
  right: 5px;
}

.styles-module__jf27DW__newsfeedItemWrapperSmall {
  min-height: 120px;
}

.styles-module__jf27DW__newsfeedItem {
  width: 100%;
  color: var(--wcom-c-blueDark);
  padding: 20px 0;
  font-weight: bold;
  line-height: 1.1;
  display: flex;
}

.styles-module__jf27DW__newsfeedItemHighlighted {
  background-color: var(--wcom-c-bgGreen);
}

.styles-module__jf27DW__contentColumn {
  width: 100%;
  max-height: 175px;
  position: relative;
}

@media (min-width: 360px) {
  .styles-module__jf27DW__contentColumn {
    width: 100%;
    max-width: none;
  }
}

.styles-module__jf27DW__date {
  width: 80px;
  padding: 0 5px;
  font-size: 14px;
}

.styles-module__jf27DW__dateWithBorder {
  border-left: 2px solid var(--wcom-c-blueDark);
}

.styles-module__jf27DW__newsInfo {
  width: 80px;
  position: relative;
}

.styles-module__jf27DW__newsInfo > span[class^="icon-"] {
  justify-content: center;
  margin-top: 15px;
  display: flex;
}

.styles-module__jf27DW__description {
  color: var(--wcom-c-grayBlue);
  margin-top: 10px;
  padding: 5px 5px 5px 10px;
  font-weight: normal;
  text-decoration: none;
  position: relative;
}

.styles-module__jf27DW__warning1 {
  border-left: 5px solid var(--wcom-c-warning1);
  background-color: var(--wcom-c-warning1Lighter);
}

.styles-module__jf27DW__warning2 {
  border-left: 5px solid var(--wcom-c-warning2);
  background-color: var(--wcom-c-warning2Lighter);
}

.styles-module__jf27DW__warning3 {
  border-left: 5px solid var(--wcom-c-warning3);
  background-color: var(--wcom-c-warning3Lighter);
}

.styles-module__jf27DW__warning4 {
  border-left: 5px solid var(--wcom-c-warning4);
  background-color: var(--wcom-c-warning4Lighter);
}

.styles-module__jf27DW__imageContainer {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
}

